import React from 'react';
import './PersonalDataTable.scss';

type Row = [string, string[], string[]];

const headings: string[] = [
  'Category of Personal Data',
  'Examples of Personal Data We Collect',
  'Categories of Third Parties With Whom We Share this Personal Data',
];

const content: Row[] = [
  [
    'Profile or Contact Data',
    ['First and last name', 'Email', 'Phone number', 'Unique identifiers such as account names and passwords'],
    ['Service Providers', 'Advertising Partners', 'Analytics Partners'],
  ],
  [
    'Payment Data',
    ['Payment card type', 'Last 4 digits of payment card', 'Billing address, phone number, and email'],
    ['Service Providers (specifically our payment processing partner, currently Stripe, Inc)'],
  ],
  [
    'Device/IP Data',
    ['IP address', 'Type of device/operating system/browser used to access the Services'],
    ['Service Providers', 'Advertising Partners', 'Analytics Partners'],
  ],
  [
    'Web Analytics',
    [
      'Web page interactions',
      'Referring webpage/source through which you accessed the Services',
      'Non-identifiable request IDs',
      'Statistics associated with the interaction between device or browser and the Services',
    ],
    ['Service Providers', 'Advertising Partners', 'Analytics Partners'],
  ],
  [
    'Social Network Data',
    [
      'Email',
      'Phone number',
      'User name',
      'IP address',
      'Information from user’s social media profile, including user’s name, employer name and job title',
    ],
    ['Service Providers', 'Advertising Partners', 'Analytics Partners'],
  ],
  [
    'Professional or Employment-Related Data',
    ['Job title', 'Employer'],
    ['Service Providers', 'Advertising Partners', 'Analytics Partners'],
  ],
  ['Geolocation Data', ['IP-address-based location information'], ['Service Providers', 'Advertising Partners', 'Analytics Partners']],
];

const PersonalDataTable = () => {
  return (
    <>
      <div className="PersonalDataTable">
        <div className="PersonalDataTable__THead">
          {headings.map((heading: string) => {
            return (
              <div key={heading} className="PersonalDataTable__TH">
                {heading}
              </div>
            );
          })}
        </div>
        <div className="PersonalDataTable__TBody">
          {content.map((row: Row) => {
            const [category, examples, thirdPartyCategories] = row;
            return (
              <div key={category} className=" PersonalDataTable__TR">
                <div className="PersonalDataTable__TD">{category}</div>
                <div className="PersonalDataTable__TD">
                  <ul>
                    {examples.map(ex => {
                      return <li key={ex}>{ex}</li>;
                    })}
                  </ul>
                </div>
                <div className="PersonalDataTable__TD">
                  <ul>
                    {thirdPartyCategories.map((tp: string) => {
                      return <li key={tp}>{tp}</li>;
                    })}
                  </ul>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="PersonalDataTable PersonalDataTable--mobile">
        <div className="PersonalDataTable__TBody">
          {content.map((row: Row, i: number) => {
            const [category, examples, thirdPartyCategories] = row;
            return (
              <div key={category} className=" PersonalDataTable__TR">
                <div className="PersonalDataTable__TD">
                  <div>
                    <div className="PersonalDataTable__TD__Heading">{headings[0]}</div>
                  </div>
                  <div> {category}</div>
                </div>
                <div className="PersonalDataTable__TD">
                  <div className="PersonalDataTable__TD__Heading">{headings[1]}</div>
                  <ul>
                    {examples.map(ex => {
                      return <li key={ex}>{ex}</li>;
                    })}
                  </ul>
                </div>
                <div className="PersonalDataTable__TD">
                  <div className="PersonalDataTable__TD__Heading">{headings[2]}</div>
                  <ul>
                    {thirdPartyCategories.map((tp: string) => {
                      return <li key={tp}>{tp}</li>;
                    })}
                  </ul>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default PersonalDataTable;
