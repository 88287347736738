import React, { useState, useEffect } from 'react';
import scrollTo from 'gatsby-plugin-smoothscroll';
import { InView } from 'react-intersection-observer';
import { Link } from 'gatsby';
import IndexLayout from '@layouts/index';
import Header from '@components/Header';
import Footer from '@components/Footer';
import PageHeader from '@components/PageHeader';
import Container from '@components/Container';
import './privacy-policy.scss';
import TOCListItem from '@components/TOCListItem';
import links from '@constants/links';
import emails from '@constants/emails';
import PersonalDataTable from '@page-components/privacy-policy/PersonalDataTable';

// import { useInView } from 'react-intersection-observer';

const PrivacyPolicy: React.FC = () => {
  const [currentSection, setCurrentSection] = useState<string>('');
  const sidebar = React.createRef<HTMLElement>();
  // const information = React.createRef<HTMLElement>();
  // const personalInformation = React.createRef<HTMLElement>();
  // const security = React.createRef<HTMLElement>();
  // const otherSites = React.createRef<HTMLElement>();
  // const contact = React.createRef<HTMLElement>();

  // const intersectOptions = {
  //   rootMargin: '100px',
  //   threshold: 1,
  // };
  // const [information, inView, entry] = useInView(intersectOptions);

  useEffect(() => {
    const Stickyfill = require('stickyfilljs');

    Stickyfill.add(sidebar);
  }, []);

  const onTOCListItemClick = (slug: string) => {
    setCurrentSection(slug);
    scrollTo(`#${slug}`);
  };

  return (
    <>
      <IndexLayout
        pageName="privacy-policy"
        helmet={{ title: 'Privacy Policy - TileDB', description: 'Learn more about our Privacy Policy.' }}
      >
        <Header />
        <PageHeader title="Privacy Policy" date={{ day: 8, month: 3, year: 2021 }} />
        <Container>
          <main className="main">
            <div className="wrapper">
              <article className="legal-article">
                <p>
                  At TileDB, we take your privacy seriously. Please read this Privacy Policy to learn how we treat your personal data.{' '}
                  <strong>
                    By using or accessing our Services in any manner, you acknowledge that you accept the practices and policies outlined
                    below, and you hereby consent that we will collect, use and share your information as described in this Privacy Policy.
                  </strong>
                </p>
                <p>
                  Remember that your use of TileDB Services is at all times subject to our{' '}
                  <Link to={links.terms} className="hover-link">
                    Terms of Use
                  </Link>
                  , which incorporates this Privacy Policy. Any terms we use in this Policy without defining them have the definitions given
                  to them in the Terms of Use.
                </p>

                <InView
                  as="section"
                  onChange={(inView) => {
                    if (inView) setCurrentSection('what-this-privacy-policy-covers');
                  }}
                >
                  <h2 id="what-this-privacy-policy-covers">What this privacy policy covers</h2>
                </InView>

                <p>
                  This Privacy Policy covers how we treat Personal Data that we gather when you access or use our Services. “Personal Data”
                  means any information that identifies or relates to a particular individual and also includes information referred to as
                  “personally identifiable information” or “personal information” under applicable data privacy laws, rules or regulations.
                  This Privacy Policy does not cover the practices of companies we don’t own or control or people we don’t manage.
                </p>

                <InView
                  as="section"
                  onChange={(inView) => {
                    if (inView) setCurrentSection('personal-data');
                  }}
                >
                  <h2 id="personal-data">Personal Data</h2>
                </InView>

                <InView
                  as="section"
                  onChange={(inView) => {
                    if (inView) setCurrentSection('categories-of-personal-data');
                  }}
                >
                  <h3 id="categories-of-personal-data">Categories of personal data we collect</h3>
                </InView>
                <p>This chart details the categories of Personal Data that we collect and have collected over the past 12 months:</p>
                <PersonalDataTable />

                <InView
                  as="section"
                  onChange={(inView) => {
                    if (inView) setCurrentSection('categories-of-sources');
                  }}
                >
                  <h3 id="categories-of-sources">Categories of Sources of Personal Data</h3>
                </InView>

                <p>We collect Personal Data about you from the following categories of sources:</p>
                <ul className="deep-nesting">
                  <li>
                    <strong>You</strong>
                    <ul>
                      <li>
                        <div>When you provide such information directly to us.</div>
                        <ul>
                          <li>When you create an account or use our interactive tools and Services.</li>
                          <li>
                            When you voluntarily provide information in free-form text boxes through the Services or through responses to
                            surveys or questionnaires.
                          </li>
                          <li>When you send us an email or otherwise contact us.</li>
                        </ul>
                      </li>
                      <li>
                        <div>When you use the Services and such information is collected automatically.</div>
                        <ul>
                          <li>Through Cookies (defined in the “Tracking Tools, Advertising and Opt-Out” section below).</li>
                          <li>If you use a location-enabled browser, we may receive information about your location.</li>
                          <li>
                            If you download and install certain applications and software we make available, we may receive and collect
                            information transmitted from your computing device for the purpose of providing you the relevant Services, such
                            as information regarding when you are logged on and available to receive updates or alert notices.
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <strong>Public Records</strong>
                    <ul>
                      <li>
                        <div>From the government, public social media posts or other sources.</div>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <strong>Third Parties</strong>
                    <ul>
                      <li>
                        <div>Vendors</div>
                        <ul>
                          <li>
                            We may use analytics providers to analyze how you interact and engage with the Services, or third parties may
                            help us provide you with customer support.
                          </li>
                          <li>We may use vendors to obtain information to generate leads and create user profiles.</li>
                        </ul>
                      </li>
                      <li>
                        <div>Advertising Partners</div>
                        <ul>
                          <li>
                            We receive information about you from some of our vendors who assist us with marketing or promotional services
                            related to how you interact with our websites, applications, products, Services, advertisements or
                            communications.
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>

                <InView
                  as="section"
                  onChange={(inView) => {
                    if (inView) setCurrentSection('our-commercial-purposes');
                  }}
                >
                  <h3 id="our-commercial-purposes">Our Commercial or Business Purposes for Collecting Personal Data</h3>
                </InView>
                <ul className="deep-nesting">
                  <li>
                    <strong>Providing, Customizing and Improving the Services</strong>
                    <ul>
                      <li>Creating and managing your account or other user profiles.</li>
                      <li>Processing orders or other transactions; billing.</li>
                      <li>Providing you with the products, services or information you request.</li>
                      <li>Meeting or fulfilling the reason you provided the information to us.</li>
                      <li>Providing support and assistance for the Services.</li>
                      <li>Improving the Services, including testing, research, internal analytics and product development.</li>
                      <li>Personalizing the Services, website content and communications based on your preferences.</li>
                      <li>Doing fraud protection, security and debugging.</li>
                      <li>
                        Carrying out other business purposes stated when collecting your Personal Data or as otherwise set forth in
                        applicable data privacy laws.
                      </li>
                    </ul>
                  </li>

                  <li>
                    <strong>Marketing the Services</strong>
                    <ul>
                      <li>Marketing and selling the Services.</li>
                      <li>Showing you advertisements, including interest-based or online behavioral advertising.</li>
                    </ul>
                  </li>

                  <li>
                    <strong>Corresponding with You</strong>
                    <ul>
                      <li>
                        Responding to correspondence that we receive from you, contacting you when necessary or requested, and sending you
                        information about the Services.
                      </li>
                      <li>
                        Sending emails and other communications according to your preferences or that display content that we think will
                        interest you.
                      </li>
                    </ul>
                  </li>

                  <li>
                    <strong>Meeting Legal Requirements and Enforcing Legal Terms</strong>
                    <ul>
                      <li>
                        Fulfilling our legal obligations under applicable law, regulation, court order or other legal process, such as
                        preventing, detecting and investigating security incidents and potentially illegal or prohibited activities.
                      </li>
                      <li>Protecting the rights, property or safety of you or another party.</li>
                      <li>Enforcing any agreements with you.</li>
                      <li>Responding to claims that any posting or other content violates third-party rights.</li>
                      <li>Resolving disputes.</li>
                    </ul>
                  </li>
                </ul>
                <p>
                  We will not collect additional categories of Personal Data or use the Personal Data we collected for materially different,
                  unrelated or incompatible purposes without providing you notice.
                </p>

                <InView
                  as="section"
                  onChange={(inView) => {
                    if (inView) setCurrentSection('how-we-share');
                  }}
                >
                  <h2 id="how-we-share">How We Share Your Personal Data</h2>
                </InView>

                <p>
                  We disclose your Personal Data to the categories of service providers and other parties listed in this section. Depending
                  on state laws that may be applicable to you, some of these disclosures may constitute a “sale” of your Personal Data. For
                  more information, please refer to the state-specific sections below.
                </p>

                <ul className="deep-nesting">
                  <li>
                    <div>
                      <strong>Service Providers.</strong> These parties help us provide the Services or perform business functions on our
                      behalf. They include:
                    </div>
                    <ul>
                      <li>Hosting, technology and communication providers.</li>
                      <li>Security and fraud prevention consultants.</li>
                      <li>Support and customer service vendors.</li>
                      <li>
                        Payment processors.
                        <ul>
                          <li>
                            Our payment processing partner Stripe, Inc. (“Stripe”) collects your voluntarily-provided payment card
                            information necessary to process your payment.
                          </li>
                          <li>
                            Please see Stripe’s terms of service and privacy policy for information on its use and storage of your Personal
                            Data.
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <div>
                      <strong>Advertising Partners.</strong> These parties help us market our services and provide you with other offers
                      that may be of interest to you. They include:
                    </div>
                    <ul>
                      <li>Ad networks.</li>
                      <li>Marketing providers.</li>
                    </ul>
                  </li>
                  <li>
                    <div>
                      <strong>Analytics Partners.</strong> These parties provide analytics on web traffic or usage of the Services. They
                      include:
                    </div>
                    <ul>
                      <li>Companies that track how users found or were referred to the Services.</li>
                      <li>Companies that track how users interact with the Services.</li>
                    </ul>
                  </li>
                </ul>

                <h3>Legal Obligations</h3>
                <p>
                  We may share any Personal Data that we collect with third parties in conjunction with any of the activities set forth
                  under “Meeting Legal Requirements and Enforcing Legal Terms” in the “Our Commercial or Business Purposes for Collecting
                  Personal Data” section above.
                </p>

                <h3>Business Transfers</h3>
                <p>
                  All of your Personal Data that we collect may be transferred to a third party if we undergo a merger, acquisition,
                  bankruptcy or other transaction in which that third party assumes control of our business (in whole or in part). Should
                  one of these events occur, we will make reasonable efforts to notify you before your information becomes subject to
                  different privacy and security policies and practices.
                </p>

                <h3>Data that is Not Personal Data</h3>
                <p>
                  We may create aggregated, de-identified or anonymized data from the Personal Data we collect, including by removing
                  information that makes the data personally identifiable to a particular user. We may use such aggregated, de-identified or
                  anonymized data and share it with third parties for our lawful business purposes, including to analyze, build and improve
                  the Services and promote our business, provided that we will not share such data in a manner that could identify you.{' '}
                </p>

                <InView
                  as="section"
                  onChange={(inView) => {
                    if (inView) setCurrentSection('tracking-tools');
                  }}
                >
                  <h2 id="tracking-tools">Tracking Tools, Advertising and Opt-Out</h2>
                </InView>
                <p>
                  The Services use cookies and similar technologies such as pixel tags, web beacons, clear GIFs and JavaScript
                  (collectively, “Cookies”) to enable our servers to recognize your web browser, tell us how and when you visit and use our
                  Services, analyze trends, learn about our user base and operate and improve our Services. Cookies are small pieces of
                  data– usually text files – placed on your computer, tablet, phone or similar device when you use that device to access our
                  Services. We may also supplement the information we collect from you with information received from third parties,
                  including third parties that have placed their own Cookies on your device(s). Please note that because of our use of
                  Cookies, the Services do not support “Do Not Track” requests sent from a browser at this time.
                </p>

                <p>We use the following types of Cookies:</p>
                <ul>
                  <li>
                    <strong>Essential Cookies.</strong> Essential Cookies are required for providing you with features or services that you
                    have requested. For example, certain Cookies enable you to log into secure areas of our Services. Disabling these
                    Cookies may make certain features and services unavailable.
                  </li>
                  <li>
                    <strong>Functional Cookies.</strong> Functional Cookies are used to record your choices and settings regarding our
                    Services, maintain your preferences over time and recognize you when you return to our Services. These Cookies help us
                    to personalize our content for you, greet you by name and remember your preferences (for example, your choice of
                    language or region).
                  </li>
                  <li>
                    <strong>Performance/Analytical Cookies.</strong> Performance/Analytical Cookies allow us to understand how visitors use
                    our Services. They do this by collecting information about the number of visitors to the Services, what pages visitors
                    view on our Services and how long visitors are viewing pages on the Services. Performance/Analytical Cookies also help
                    us measure the performance of our advertising campaigns in order to help us improve our campaigns and the Services’
                    content for those who engage with our advertising. For example, Google Inc. (“Google”) uses cookies in connection with
                    its Google Analytics services. Google’s ability to use and share information collected by Google Analytics about your
                    visits to the Services is subject to the Google Analytics Terms of Use and the Google Privacy Policy. You have the
                    option to opt-out of Google’s use of Cookies by visiting the Google advertising opt-out page at
                    www.google.com/privacy_ads.html or the Google Analytics Opt-out Browser Add-on at{' '}
                    <a className="hover-link" href="https://tools.google.com/dlpage/gaoptout/">
                      https://tools.google.com/dlpage/gaoptout/
                    </a>
                    .
                  </li>
                  <li>
                    <strong>Retargeting/Advertising Cookies.</strong> Retargeting/Advertising Cookies collect data about your online
                    activity and identify your interests so that we can provide advertising that we believe is relevant to you. For more
                    information about this, please see the section below titled “Information about Interest-Based Advertisements.”
                  </li>
                </ul>

                <p>
                  You can decide whether or not to accept Cookies through your internet browser’s settings. Most browsers have an option for
                  turning off the Cookie feature, which will prevent your browser from accepting new Cookies, as well as (depending on the
                  sophistication of your browser software) allow you to decide on acceptance of each new Cookie in a variety of ways. You
                  can also delete all Cookies that are already on your device. If you do this, however, you may have to manually adjust some
                  preferences every time you visit our website and some of the Services and functionalities may not work.
                </p>

                <p>
                  To explore what Cookie settings are available to you, look in the “preferences” or “options” section of your browser’s
                  menu. To find out more information about Cookies, including information about how to manage and delete Cookies, please
                  visit
                  <a href="http://www.allaboutcookies.org/" className="hover-link">
                    http://www.allaboutcookies.org/
                  </a>{' '}
                  or{' '}
                  <a href="https://ico.org.uk/for-the-public/online/cookies/" className="hover-link">
                    https://ico.org.uk/for-the-public/online/cookies/
                  </a>{' '}
                  if you are located in the European Union.
                </p>

                <h3>Information about Interest-Based Advertisements:</h3>
                <p>
                  We may serve advertisements, and also allow third-party ad networks, including third-party ad servers, ad agencies, ad
                  technology vendors and research firms, to serve advertisements through the Services. These advertisements may be targeted
                  to users who fit certain general profile categories or display certain preferences or behaviors (“Interest-Based Ads”).
                  Information for Interest-Based Ads (including Personal Data) may be provided to us by you, or derived from the usage
                  patterns of particular users on the Services and/or services of third parties. Such information may be gathered through
                  tracking users’ activities across time and unaffiliated properties, including when you leave the Services. To accomplish
                  this, we or our service providers may deliver Cookies, including a file (known as a “web beacon”) from an ad network to
                  you through the Services. Web beacons allow ad networks to provide anonymized, aggregated auditing, research and reporting
                  for us and for advertisers. Web beacons also enable ad networks to serve targeted advertisements to you when you visit
                  other websites. Web beacons allow ad networks to view, edit or set their own Cookies on your browser, just as if you had
                  requested a web page from their site.
                </p>

                <p>
                  We comply with the Digital Advertising Alliance (“DAA”) Self-Regulatory Principles for Online Behavioral Advertising.
                  Through the DAA and Network Advertising Initiative (“NAI”), several media and marketing associations have developed an
                  industry self-regulatory program to give consumers a better understanding of, and greater control over, ads that are
                  customized based a consumer’s online behavior across different websites and properties. To make choices about
                  Interest-Based Ads from participating third parties, including to opt-out of receiving behaviorally targeted
                  advertisements from participating organizations, please visit the DAA’s or NAI’s consumer opt-out pages, which are located
                  at
                  <a href="http://www.networkadvertising.org/choices/" className="hover-link">
                    http://www.networkadvertising.org/choices/
                  </a>{' '}
                  or www.aboutads.info/choices. Users in the European Union should visit the European Interactive Digital Advertising
                  Alliance’s user information website{' '}
                  <a href="http://www.youronlinechoices.eu/" className="hover-link">
                    http://www.youronlinechoices.eu/
                  </a>
                  .
                </p>

                <InView
                  as="section"
                  onChange={(inView) => {
                    if (inView) setCurrentSection('data-security');
                  }}
                >
                  <h2 id="data-security">Data Security and Retention</h2>
                </InView>
                <p>
                  We seek to protect your Personal Data from unauthorized access, use and disclosure using appropriate physical, technical,
                  organizational and administrative security measures based on the type of Personal Data and how we are processing that
                  data. You should also help protect your data by appropriately selecting and protecting your password and/or other sign-on
                  mechanism; limiting access to your computer or device and browser; and signing off after you have finished accessing your
                  account. Although we work to protect the security of your account and other data that we hold in our records, please be
                  aware that no method of transmitting data over the internet or storing data is completely secure.
                </p>

                <p>
                  We retain Personal Data about you for as long as you have an open account with us or as otherwise necessary to provide you
                  with our Services. In some cases we retain Personal Data for longer, if doing so is necessary to comply with our legal
                  obligations, resolve disputes or collect fees owed, or is otherwise permitted or required by applicable law, rule or
                  regulation. We may further retain information in an anonymous or aggregated form where that information would not identify
                  you personally.
                </p>

                <InView
                  as="section"
                  onChange={(inView) => {
                    if (inView) setCurrentSection('personal-data-of-children');
                  }}
                >
                  <h2 id="personal-data-of-children">Personal Data of Children</h2>
                </InView>
                <p>
                  As noted in the Terms of Use, we do not knowingly collect or solicit Personal Data about children under 13 years of age;
                  if you are a child under the age of 13, please do not attempt to register for or otherwise use the Services or send us any
                  Personal Data. If we learn we have collected Personal Data from a child under 13 years of age, we will delete that
                  information as quickly as possible. If you believe that a child under 13 years of age may have provided Personal Data to
                  us, please contact us at{' '}
                  <a className="hover-link" href={`mailto:${emails.support}`}>
                    {emails.support}
                  </a>
                  .
                </p>

                <InView
                  as="section"
                  onChange={(inView) => {
                    if (inView) setCurrentSection('state-law-privacy-rights');
                  }}
                >
                  <h2 id="state-law-privacy-rights">State Law Privacy Rights</h2>
                </InView>
                <h3>California Resident Rights</h3>
                <p>
                  Under California Civil Code Sections 1798.83-1798.84, California residents are entitled to contact us to prevent
                  disclosure of Personal Data to third parties for such third parties’ direct marketing purposes; in order to submit such a
                  request, please contact us at{' '}
                  <a className="hover-link" href={`mailto:${emails.support}`}>
                    {emails.support}
                  </a>
                  .
                </p>

                <h3>Nevada Resident Rights</h3>
                <p>
                  If you are a resident of Nevada, you have the right to opt-out of the sale of certain Personal Data to third parties who
                  intend to license or sell that Personal Data. You can exercise this right by contacting us at{' '}
                  <a className="hover-link" href={`mailto:${emails.support}`}>
                    {emails.support}
                  </a>{' '}
                  with the subject line “Nevada Do Not Sell Request” and providing us with your name and the email address associated with
                  your account. We do not sell Personal Data received from you.
                </p>

                <InView
                  as="section"
                  onChange={(inView) => {
                    if (inView) setCurrentSection('changes-to-this-privacy-policy');
                  }}
                >
                  <h2 id="changes-to-this-privacy-policy">Changes to this Privacy Policy</h2>
                </InView>
                <p>
                  We’re constantly trying to improve our Services, so we may need to change this Privacy Policy from time to time, but we
                  will alert you to any such changes by placing a notice on the TileDB website, by sending you an email and/or by some other
                  means. Please note that if you’ve opted not to receive legal notice emails from us (or you haven’t provided us with your
                  email address), those legal notices will still govern your use of the Services, and you are still responsible for reading
                  and understanding them. If you use the Services after any changes to the Privacy Policy have been posted, that means you
                  agree to all of the changes. Use of information we collect is subject to the Privacy Policy in effect at the time such
                  information is collected.
                </p>

                <InView
                  as="section"
                  onChange={(inView) => {
                    if (inView) setCurrentSection('contact-information');
                  }}
                >
                  <h2 id="contact-information">Contact Information:</h2>
                </InView>
                <p>
                  If you have any questions or comments about this Privacy Policy, the ways in which we collect and use your Personal Data
                  or your choices and rights regarding such collection and use, please do not hesitate to contact us at:
                </p>
                <ul>
                  <li>
                    <a className="hover-link" href="/">
                      www.tiledb.com
                    </a>
                  </li>
                  <li>
                    <a className="hover-link" href={`mailto:${emails.support}`}>
                      {emails.support}
                    </a>
                  </li>
                  <li>TileDB, Inc., CIC, 1 Broadway Cambridge, MA 02142, USA</li>
                </ul>
              </article>
              <aside className="contents" ref={sidebar}>
                <h3>Contents:</h3>
                <ul className="toc">
                  <TOCListItem
                    currentSection={currentSection}
                    slug="what-this-privacy-policy-covers"
                    label="What this privacy policy covers"
                    onClick={onTOCListItemClick}
                  />
                  <TOCListItem currentSection={currentSection} slug="personal-data" label="Personal Data" onClick={onTOCListItemClick} />
                  <TOCListItem
                    currentSection={currentSection}
                    slug="categories-of-personal-data"
                    label="Categories of Personal Data We Collect"
                    onClick={onTOCListItemClick}
                    indent="1"
                  />
                  <TOCListItem
                    currentSection={currentSection}
                    slug="categories-of-sources"
                    label="Categories of Sources of Personal Data"
                    onClick={onTOCListItemClick}
                    indent="1"
                  />
                  <TOCListItem
                    currentSection={currentSection}
                    slug="our-commercial-purposes"
                    label="Our Commercial or Business Purposes for Collecting Personal Data"
                    onClick={onTOCListItemClick}
                    indent="1"
                  />
                  <TOCListItem
                    currentSection={currentSection}
                    slug="how-we-share"
                    label="How We Share Your Personal Data"
                    onClick={onTOCListItemClick}
                  />
                  <TOCListItem
                    currentSection={currentSection}
                    slug="tracking-tools"
                    label="Tracking Tools, Advertising and Opt-Out"
                    onClick={onTOCListItemClick}
                  />
                  <TOCListItem
                    currentSection={currentSection}
                    slug="data-security"
                    label="Data Security and Retention"
                    onClick={onTOCListItemClick}
                  />
                  <TOCListItem
                    currentSection={currentSection}
                    slug="personal-data-of-children"
                    label="Personal Data of Children"
                    onClick={onTOCListItemClick}
                  />
                  <TOCListItem
                    currentSection={currentSection}
                    slug="state-law-privacy-rights"
                    label="State Law Privacy Rights"
                    onClick={onTOCListItemClick}
                  />
                  <TOCListItem
                    currentSection={currentSection}
                    slug="changes-to-this-privacy-policy"
                    label="Changes to this Privacy Policy"
                    onClick={onTOCListItemClick}
                  />
                  <TOCListItem
                    currentSection={currentSection}
                    slug="contact-information"
                    label="Contact Information"
                    onClick={onTOCListItemClick}
                  />
                </ul>
              </aside>
            </div>
          </main>
        </Container>
        <Footer />
      </IndexLayout>
    </>
  );
};

export default PrivacyPolicy;
